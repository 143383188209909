import React, { useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./App.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Bilder
import logo from "./assets/images/logo.png";

// Seiten
import LagerplatzDetails from "./components/LagerplatzDetails";
import BuchungSuccess from "./components/BuchungSuccess";
import Kontakt from "./components/Kontakt";
import Datenschutz from "./components/Datenschutz";
import Impressum from "./components/Impressum";

function App() {
  const [filteredLagerplaetze, setFilteredLagerplaetze] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  // Verfügbarkeit prüfen
  const checkAvailability = async () => {
    if (!startDate || !endDate) {
      setErrorMessage("Bitte geben Sie Start- und Enddatum ein.");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.lager.bepassion.de/api/buchungen/check-availability", // Produktions-URL
        { startDate, endDate }
      );
      setFilteredLagerplaetze(response.data);
      setErrorMessage("");
    } catch (error) {
      console.error("Fehler bei der Verfügbarkeitsprüfung:", error);
      setFilteredLagerplaetze([]);
      setErrorMessage("Keine verfügbaren Lagerplätze im gewählten Zeitraum.");
    }
  };

  // Lagerplatz-Details mit Datum öffnen
  const handleNavigateToDetails = (id) => {
    navigate(`/lagerplatz/${id}`, { state: { startDate, endDate } });
  };

  return (
    <div className="App">
      {/* Header */}
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        <div className="contact">Telefon: 01234 567890</div>
      </header>

      {/* Routen */}
      <Routes>
        {/* Startseite */}
        <Route
          path="/"
          element={
            <>
              {/* Section 1 */}
              <section className="section-1">
                <h1>Jetzt Lagerplatz mieten</h1>
                <p>Flexible Lagermöglichkeiten für Privat- und Geschäftskunden</p>
              </section>

              {/* Verfügbarkeitsprüfung */}
              <section className="availability-section">
                <h2>Geben Sie Ihre Buchungsdetails an, um nach verfügbaren Lagerplätzen zu suchen</h2>
                <div className="availability-form">
                  <label>Startdatum:</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />

                  <label>Enddatum:</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />

                  <button onClick={checkAvailability}>Jetzt Verfügbarkeit prüfen</button>
                </div>
                {errorMessage && <p className="error">{errorMessage}</p>}
              </section>

              {/* Section 2 */}
              <section className="section-2">
                <div className="step">
                  <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                  <h3>1. Lagerplatz suchen</h3>
                </div>
                <div className="step">
                  <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                  <h3>2. Daten eingeben</h3>
                </div>
                <div className="step">
                  <FontAwesomeIcon icon={faCheckCircle} className="icon" />
                  <h3>3. Sofort buchen</h3>
                </div>
              </section>

              {/* Section 3: Lagerplätze anzeigen */}
              <section className="section-3">
                <h2>Verfügbare Lagerplätze</h2>
                <div className="cards">
                  {filteredLagerplaetze.length > 0 ? (
                    filteredLagerplaetze.map((platz) => (
                      <div
                        key={platz._id}
                        onClick={() => handleNavigateToDetails(platz._id)}
                        className="card-link"
                        style={{ cursor: "pointer", textDecoration: "none", color: "inherit" }}
                      >
                        <div className="card">
                          <img
                            src={`https://api.lager.bepassion.de/uploads/${platz.image}`}
                            alt="Lagerplatz"
                            className="card-image"
                          />
                          <div className="card-content">
                            <h3>Adresse: {platz.address}</h3>
                            <p>Größe: {platz.size} m²</p>
                            <p>Volumen: {platz.volume} m³</p>
                            <p>Monatlich: {platz.monthly_price} CHF</p>
                            <p>Jährlich: {platz.yearly_price} CHF</p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Keine Lagerplätze verfügbar.</p>
                  )}
                </div>
              </section>
            </>
          }
        />

        {/* Detailseite */}
        <Route path="/lagerplatz/:id" element={<LagerplatzDetails />} />
        <Route path="/buchung-success" element={<BuchungSuccess />} />
        <Route path="/kontakt" element={<Kontakt />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
      </Routes>

      {/* Footer */}
      <footer className="footer">
        <p>Adresse: Beispielstraße 123, 12345 Musterstadt</p>
        <div className="links">
          <Link to="/datenschutz">Datenschutz</Link> |{" "}
          <Link to="/impressum">Impressum</Link>
        </div>
      </footer>
    </div>
  );
}

export default App;
