import React from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

function BuchungSuccess() {
  const location = useLocation();
  const { state } = location;

  if (!state || !state.buchung) {
    return <h2>Keine Buchungsdetails verfügbar.</h2>;
  }

  const { buchung } = state;

  return (
    <div className="success-container">
      <h1>
        <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} /> Buchung erfolgreich!
      </h1>
      <h2>Buchungsdetails:</h2>
      <p><strong>Buchungs-ID:</strong> {buchung._id}</p>
      <p><strong>Startdatum:</strong> {new Date(buchung.startDate).toLocaleDateString()}</p>
      <p><strong>Enddatum:</strong> {new Date(buchung.endDate).toLocaleDateString()}</p>
      <p><strong>Gesamtpreis:</strong> {buchung.totalPrice} CHF</p>
    </div>
  );
}

export default BuchungSuccess;
