import React, { useState } from "react";

const Kontakt = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    nachricht: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Formular abgesendet:", formData);
    alert("Nachricht wurde erfolgreich gesendet!");
    setFormData({ name: "", email: "", nachricht: "" }); // Formular leeren
  };

  return (
    <div className="page-content">
      <h1>Kontaktformular</h1>
      <form onSubmit={handleSubmit} className="contact-form">
        <label>
          Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          E-Mail:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Nachricht:
          <textarea
            name="nachricht"
            value={formData.nachricht}
            onChange={handleChange}
            required
          ></textarea>
        </label>
        <button type="submit">Absenden</button>
      </form>
    </div>
  );
};

export default Kontakt;
