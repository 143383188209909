import React from "react";

const Datenschutz = () => {
  return (
    <div className="page-content">
      <h1>Datenschutzerklärung</h1>
      <p>
        Hier kommt der Inhalt der Datenschutzerklärung hin. Gib an, wie
        personenbezogene Daten verarbeitet werden und welche Rechte die Nutzer haben.
      </p>
    </div>
  );
};

export default Datenschutz;
