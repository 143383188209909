import React from "react";

const Impressum = () => {
  return (
    <div className="page-content">
      <h1>Impressum</h1>
      <p>
        Betreiber der Seite:<br />
        <strong>Max Mustermann</strong><br />
        Beispielstraße 123<br />
        12345 Musterstadt<br />
        Telefon: 01234 567890<br />
        E-Mail: info@beispiel.de
      </p>
    </div>
  );
};

export default Impressum;
