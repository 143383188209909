import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./LagerplatzDetails.css";

const API_BASE_URL = "https://api.lager.bepassion.de"; // Backend-API-Domain

function LagerplatzDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [lagerplatz, setLagerplatz] = useState(null);
  const [startDate] = useState(state?.startDate || ""); // Startdatum übernehmen
  const [endDate] = useState(state?.endDate || ""); // Enddatum übernehmen
  const [totalPrice, setTotalPrice] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
    phone: "",
    email: "",
  });
  const [error, setError] = useState(null); // Fehlerbehandlung

  // Lagerplatz-Daten abrufen
  useEffect(() => {
    const fetchLagerplatz = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/lagerplaetze/${id}`);
        setLagerplatz(response.data);
      } catch (error) {
        console.error("Fehler beim Laden des Lagerplatzes:", error);
        setError("Lagerplatz konnte nicht geladen werden.");
      }
    };
    fetchLagerplatz();
  }, [id]);

  // Preisberechnung
  const calculatePrice = useCallback(() => {
    if (!startDate || !endDate || !lagerplatz) return;

    const start = new Date(startDate);
    const end = new Date(endDate);

    let totalMonths =
      (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    if (end.getDate() > start.getDate()) totalMonths += 1;

    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    let price = years * lagerplatz.yearly_price + months * lagerplatz.monthly_price;
    setTotalPrice(price);
  }, [startDate, endDate, lagerplatz]);

  useEffect(() => {
    calculatePrice();
  }, [calculatePrice]);

  // Formularfelder aktualisieren
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Formular abschicken
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${API_BASE_URL}/api/buchungen`, {
        lagerplatzId: lagerplatz._id,
        startDate,
        endDate,
        totalPrice,
        ...formData,
      });

      if (response.status === 201) {
        navigate("/buchung-success", { state: { buchung: response.data.buchung } });
      }
    } catch (error) {
      console.error("Fehler beim Senden der Buchung:", error);
      alert("Fehler beim Speichern der Buchung. Bitte versuche es erneut.");
    }
  };

  if (error) return <p>{error}</p>; // Fehler anzeigen
  if (!lagerplatz) return <p>Lagerplatz wird geladen...</p>;

  return (
    <div className="lagerplatz-details">
      {/* Header */}
      <div
        className="details-header"
        style={{
          backgroundImage: `linear-gradient(rgba(255, 0, 0, 0.6), rgba(255, 0, 0, 0.6)), url(${API_BASE_URL}${lagerplatz.image})`,
        }}
      >
        <h1>{lagerplatz.address}</h1>
      </div>

      {/* Details */}
      <div className="details-content">
        <h2>Details zum Lagerplatz</h2>
        <p>Größe: {lagerplatz.size} m²</p>
        <p>Volumen: {lagerplatz.volume} m³</p>
        <p>Monatspreis: {lagerplatz.monthly_price} CHF</p>
        <p>Jahrespreis: {lagerplatz.yearly_price} CHF</p>
      </div>

      {/* Buchungsformular */}
      <div className="booking-form">
        <h2>Lagerplatz buchen</h2>
        <form onSubmit={handleSubmit}>
          <label>Startdatum:</label>
          <input type="date" value={startDate} readOnly />

          <label>Enddatum:</label>
          <input type="date" value={endDate} readOnly />

          <h3>Gesamtpreis: {totalPrice} CHF</h3>

          <label>Vorname:</label>
          <input type="text" name="firstName" onChange={handleInputChange} required />

          <label>Nachname:</label>
          <input type="text" name="lastName" onChange={handleInputChange} required />

          <label>Straße:</label>
          <input type="text" name="street" onChange={handleInputChange} required />

          <label>Hausnummer:</label>
          <input type="text" name="houseNumber" onChange={handleInputChange} required />

          <label>PLZ:</label>
          <input type="text" name="postalCode" onChange={handleInputChange} required />

          <label>Stadt:</label>
          <input type="text" name="city" onChange={handleInputChange} required />

          <label>Telefonnummer:</label>
          <input type="text" name="phone" onChange={handleInputChange} required />

          <label>Email:</label>
          <input type="email" name="email" onChange={handleInputChange} required />

          <button type="submit">Buchung abschließen</button>
        </form>
      </div>
    </div>
  );
}

export default LagerplatzDetails;
